<template>
  <div>
    <!-- 数据展示 -->
    <a-table
      tid="2"
      :loading="loading"
      :size="tableSize"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :pagination="false"
    >
      <span slot="operation" slot-scope="text, record">
        <a @click="handleUnbind(record)(record, undefined)"> <a-icon type="edit" />解绑 </a>
      </span>
    </a-table>
    <!-- 分页 -->
    <a-pagination
      class="ant-table-pagination"
      show-size-changer
      show-quick-jumper
      :current="queryParam.pageNum"
      :total="total"
      :page-size="queryParam.pageSize"
      :showTotal="(total) => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      @change="changeSize"
    />
  </div>
</template>

<script>
import {
  listDeviceEmployee,
  delDeviceEmployee,
  exportDeviceEmployee,
  unbindDeviceEmployee
} from '@/api/jt808/deviceEmployee'

export default {
  name: 'DeviceEmployee',
  components: {},
  data() {
    return {
      list: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        deviceId: null,
        identityCard: null,
        status: null,
        tenantId: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '设备编号',
          dataIndex: 'deviceId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '姓名',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '身份证号码',
          dataIndex: 'identityCard',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询设备-雇员绑定列表 */
    getList(queryParam) {
      this.loading = true
      if (queryParam) {
        this.queryParam = Object.assign(this.queryParam, queryParam)
      }
      listDeviceEmployee(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        deviceId: undefined,
        identityCard: undefined,
        status: undefined,
        tenantId: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delDeviceEmployee(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportDeviceEmployee(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    handleUnbind(record) {
      const queryParam = {
        deviceId: record.deviceId,
        idCardNo: record.identityCard
      }
      this.$confirm({
        title: '确认要解绑吗?',
        onOk: () => {
          unbindDeviceEmployee(queryParam).then((response) => {
            this.getList()
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
